import external from "./spotlight/external";
import guide from "./spotlight/guide";
import paper from "./spotlight/paper.js";
import nav from "./spotlight/nav-links.js";
import party from "./spotlight/party.js";
export default async () => {
  const navItems = nav();
  const partyItems = await party();
  const paperItems = await paper();
  const externalItems = await external();
  return [...guide, ...paperItems, ...navItems, ...externalItems, ...partyItems];
};
