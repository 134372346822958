import menuItems from "../admin-menu";

export default () => {
  const menu = [];
  menuItems.forEach((item) => {
    if (item.children) {
      item.children.forEach((child) => {
        menu.push({
          title: child.title,
          description: `${item.divider} — ${child.title}`,
          icon: child.icon,
          permission: child.permission,
          flags: child.flags,
          data: { to: child.to },
          type: "internal",
        });
      });
    } else {
      menu.push({
        title: item.title,
        description: item.title,
        icon: item.icon,
        permission: item.permission,
        flags: item.flags,
        data: { to: item.to },
        type: "internal",
      });
    }
  });
  return menu;
};
