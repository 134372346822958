<template>
  <div>
    <h4>Recebimentos Externos</h4>
    <p>
      A seção "Recebimentos Externos" se refere a vendas realizadas por um
      vendedor fora do sistema online, essas são classificadas como
      <code>Offline</code>. Como essas transações ocorrem fora do sistema, os
      valores das vendas não aparecem no relatório. Em vez disso, esses fundos
      permanecem com o promoter responsável pela venda.
    </p>
    <p>
      Dentro da aba "Recebimentos Externos", você tem a capacidade de gerenciar
      os débitos de cada promoter. Aqui, você também pode registrar qualquer
      valor recebido, seja ele parcial, total ou mesmo se exceder o valor do
      débito, gerando assim um crédito para o promoter.
    </p>
    <p>
      Os recebimentos registrados serão agrupados por data e exibidos no
      financeiro. Isso facilita o acompanhamento diário das transações e
      proporciona um controle mais efetivo sobre o fluxo de caixa.
    </p>

    <!-- Caso seja reembolsado o ingresso, o debito
       continua aparecendo na conta do promoter, pois cabe a organização fazer o reembolso -->

    <template v-if="activeParties.length">
      <h6>Links rápidos</h6>
      <div class="d-flex flex-column gap-2">
        <v-card
          v-for="party in activeParties"
          :key="party.id"
          class="d-flex pa-4 rounded-lg gap-4 align-center"
          elevation="0"
          outlined
          @click="goToParty(party)"
        >
          <div>
            <v-img
              :src="party.cover"
              height="60px"
              :width="(60 * 16) / 9"
              :aspect-ratio="16 / 9"
            />
          </div>
          <div>
            <p
              class="text-overline mb-0"
              style="line-height: normal; font-size: 10px !important"
            >
              <b>ir para Recebimentos Externos</b>
            </p>
            <h6>{{ party.name }}</h6>
          </div>
        </v-card>
      </div>
    </template>
  </div>
</template>

<script>
export const manifest = {
  id: "paper:offlinePayment",
  title: "Como funciona o Recebimento Externo?",
  description: "Veja como funciona o recebimento externo no sistema.",
  keywords: ["recebimento", "externo", "venda externa", "venda offline"],
  relevantIn: [
    "/admin/organization/parties",
    "/admin/organization/parties/:id/management",
    "/admin/organization/parties/:id/management#offlinePayments",
  ],
};

import { mapGetters } from "vuex";
import PartyStatus from "@/utils/partyStatus";
export default {
  methods: {
    goToParty(party) {
      this.$emit("close");
      this.$router
        .push({
          name: "admin.party.management",
          params: { partyId: party.id },
          hash: "#offlinePayments",
        })
        .catch(() => {});
    },
  },
  computed: {
    ...mapGetters("organization", ["parties"]),
    activeParties() {
      return this.parties.filter((p) => {
        const s = new PartyStatus(p);
        return s.isActive;
      });
    },
  },
};
</script>

<style></style>
