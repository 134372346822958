<template>
  <div>
    <h4>Virada automática de lote</h4>
    <p>
      A virada automática de lote é uma funcionalidade que permite que o sistema
      faça a virada de lote automaticamente, sem a necessidade de um usuário
      realizar a virada manualmente.
    </p>
    <p>
      Para configurar a virada automática de lote, basta acessar a aba de
      edição de lote e marcar <i>Inicio das vendas: <code>Após o anterior</code></i>.
    </p>
    <p>
      O lote anterior deve seguir as seguintes regras:
      <ul>
        <li> Estar ativo e com data de fim maior que hoje </li>
        <li> Custar menos que o lote atual </li>
        <li> Ter a quantidade de ingressos disponíveis</li>
        <li> Ter a venda online igual a deste lote </li>
      </ul>
    </p>

    <!-- Caso seja reembolsado o ingresso, o debito
       continua aparecendo na conta do promoter, pois cabe a organização fazer o reembolso -->

    <template v-if="activeParties.length">
      <h6>Links rápidos</h6>
      <div class="d-flex flex-column gap-2">
        <v-card
          v-for="party in activeParties"
          :key="party.id"
          class="d-flex pa-4 rounded-lg gap-4 align-center"
          elevation="0"
          outlined
          @click="goToParty(party)"
        >
          <div>
            <v-img
              :src="party.cover"
              height="60px"
              :width="(60 * 16) / 9"
              :aspect-ratio="16 / 9"
            />
          </div>
          <div>
            <p
              class="text-overline mb-0"
              style="line-height: normal; font-size: 10px !important"
            >
              <b>ir para Recebimentos Externos</b>
            </p>
            <h6>{{ party.name }}</h6>
          </div>
        </v-card>
      </div>
    </template>
  </div>
</template>

<script>
export const manifest = {
  id: "paper:admin:nextTicketblock",
  title: "Virada automática de lote",
  description: "Entenda como funciona a virada automática de lote",
  keywords: ["virada", "lote", "automática", "automático"],
  relevantIn: [
    "/admin/organization/parties",
    "/admin/organization/parties/:id/management",
    "/admin/organization/parties/:id/management#tickets",
  ],
};

import { mapGetters } from "vuex";
import PartyStatus from "@/utils/partyStatus";
export default {
  methods: {
    goToParty(party) {
      this.$emit("close");
      this.$router
        .push({
          name: "admin.party.management",
          params: { partyId: party.id },
          hash: "#offlinePayments",
        })
        .catch(() => {});
    },
  },
  computed: {
    ...mapGetters("organization", ["parties"]),
    activeParties() {
      return this.parties.filter((p) => {
        const s = new PartyStatus(p);
        return s.isActive;
      });
    },
  },
};
</script>

<style></style>
