<template>
  <v-card elevation="0" class="rounded-xl" v-if="search || suggestions.length">
    <vue-perfect-scrollbar
      :settings="{ suppressScrollX: true, wheelPropagation: false }"
      style="max-height: 65dvh; max-height: 65vh"
    >
      <div class="d-flex flex-column gap-2 pb-4">
        <template v-if="suggestions.length && (!search || !results.length)">
          <v-card style="position: sticky; top: 0; z-index: 2" elevation="0">
            <p class="text-body-1 font-weight-medium pt-4 px-4">
              <v-icon>mdi-shimmer</v-icon> Sugestões
            </p>
          </v-card>
          <spotlight-item
            v-for="(result, index) in suggestions"
            :key="result.id"
            :item="result"
            :focus="index == focusIndex"
            @focus="$emit('focus', index)"
            class="mx-4"
          />
        </template>
        <template v-if="results.length">
          <v-card style="position: sticky; top: 0; z-index: 2" elevation="0">
            <p class="text-body-1 font-weight-medium pt-4 px-4">
              <v-icon>mdi-magnify</v-icon>
              Resultados
            </p>
          </v-card>
          <spotlight-item
            v-for="({ item: result }, index) in results"
            :key="result.id"
            :item="result"
            :focus="index == focusIndex"
            @focus="$emit('focus', index)"
            class="mx-4"
          />
        </template>
        <template v-if="search && !results.length">
          <v-divider v-if="suggestions.length" />
          <i class="text-body-2 text-center">
            Nada encontrado. Mais conteúdos serão adicionados em breve!
          </i>
        </template>
      </div>
    </vue-perfect-scrollbar>
  </v-card>
</template>

<script>
import SpotlightItem from "./SpotlightItem.vue";
export default {
  components: { SpotlightItem },
  data: () => ({
    types: {
      guide: {
        title: "Guia interativo",
        icon: "mdi-shimmer",
      },
      external: {
        title: "Link Externo",
        icon: "mdi-open-in-new",
      },
      internal: {
        title: "Link Interno",
        icon: "mdi-link-variant",
      },
      paper: {
        title: "Documento",
        icon: "mdi-file-document-outline",
      },
    },
  }),
  methods: {
    run(result) {
      this.$emit("run", result);
    },
  },
  props: {
    suggestions: {
      type: Array,
      default: () => [],
    },
    results: {
      type: Array,
      default: () => [],
    },
    focusIndex: {
      type: Number,
      default: 0,
    },
    search: {
      type: String,
      default: "",
    },
  },
};
</script>

<style></style>
