<template>
  <v-dialog v-model="isOpen" @click:outside="close" max-width="600px" content-class="rounded-xl">
    <v-card class="pa-8">
      <div class="py-16 d-flex justify-center" v-if="loading">
        <v-progress-circular
          indeterminate
          size="45"
          width="4"
          color="primary"
        />
      </div>
      <component v-else :is="currentPaper" @close="close"></component>
    </v-card>
  </v-dialog>
</template>

<script>
const paperComponents = {};
const context = require.context("./papers", true, /\.vue$/);

context.keys().forEach((key) => {
  const componentName = key.replace(/^\.\/(.*)\.\w+$/, "$1");
  paperComponents[componentName] = () => context(key);
});

export default {
  data: () => ({
    isOpen: false,
    loading: true,
    currentPaper: null,
  }),
  methods: {
    async loadPaper(paperName) {
      try {
        this.loading = true;
        if (paperComponents[paperName]) {
          const paper = await paperComponents[paperName]();
          this.currentPaper = paper.default;
        } else
          throw new Error({ code: 404, message: "Documento não encontrado" });
      } catch (e) {
        this.currentPaper = () => import("./papers/_404.vue");
      } finally {
        this.loading = false;
      }
    },
    run(data) {
      this.isOpen = true;
      this.loadPaper(data.paper);
    },
    close() {
      this.isOpen = false;
      this.loading = true;
      this.currentPaper = null;
    },
  },
  mounted() {
    this.$root.$on("paper:run", this.run);
  },
};
</script>

<style></style>
