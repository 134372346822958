<template>
  <v-dialog
    v-model="isOpen"
    content-class="align-self-start pt-16 pb-7 my-0 elevation-0 overflow-hidden"
    :overlay-opacity="0.85"
    max-width="700px"
    class=""
    eager
   
  >
    <div class="d-flex flex-column gap-4">
      <v-card elevation="0" class="rounded-xl">
        <v-text-field
          ref="input"
          v-model="search"
          outlined
          hide-details
          :placeholder="hint"
          :type="isMath ? 'tel' : 'text'"
          @keydown.enter="runFirst"
          @keydown.prevent.down="selectNext"
          @keydown.prevent.up="selectPrevious"
        >
          <template v-slot:prepend-inner>
            <v-progress-circular
              v-if="loading"
              indeterminate
              size="22"
              width="2"
              color="primary"
              class="mx-2"
            />
            <v-icon v-else left right>
              {{ isMath ? "mdi-calculator-variant" : "mdi-magnify" }}
            </v-icon>
          </template>
        </v-text-field>
      </v-card>

      <!-- Results -->
      <template v-if="!loading">
        <v-card
          v-if="isMath"
          class="rounded-xl px-4 py-2 d-flex align-center justify-space-between"
        >
          <div>
            <p class="text-overline mb-0">Resultado</p>
            <h2>
              {{ mathResult?.isApproximation ? "≈" : "=" }}
              {{ mathResult?.value }}
            </h2>
          </div>
          <div>
            <v-btn
              @click="mathResultToSearch"
              icon
              :disabled="!mathResult"
              class="ml-4"
              color="primary"
            >
              <v-icon>mdi-arrow-up-right-bold</v-icon>
            </v-btn>
          </div>
        </v-card>
        <spotlight-results
          v-else
          :results="results"
          :suggestions="suggestions"
          :focus-index="focusIndex"
          :search="search"
          @run="run"
          @focus="focusIndex = $event"
        />
        <p
          v-if="!openUsingShortcut && !supportsTouch"
          class="text-center white--text"
        >
          <small>
            Dica: Utilize <kbd>Ctrl</kbd> + <kbd>;</kbd> para abrir rapidamente
          </small>
        </p>
      </template>
    </div>
  </v-dialog>
</template>

<script>
import itemsF from "@/data/spotlight-items";
import Fuse from "fuse.js";
import { mapGetters } from "vuex";
import SpotlightResults from "./SpotlightResults.vue";
import nerdamer from "nerdamer/nerdamer.core";

nerdamer.setVar("vida", 14);
nerdamer.setVar("verdade", 14);
nerdamer.setVar("universo", 14);

export default {
  components: { SpotlightResults },
  data: () => ({
    initialized: false,
    loading: true,
    search: "",
    isOpen: false,
    hint: null,
    hintPhases: [
      "Como criar um evento...",
      "Como alterar o local de um evento...",
      "Como funciona as filiações...",
    ],
    results: [],
    suggestions: [],
    isMath: false,
    mathResult: null,
    nerdamer: nerdamer,
    fuse: null,
    focusIndex: 0,
    resultLimit: 8,
    openUsingShortcut: false,
    supportsTouch:
      "ontouchstart" in window || window.navigator.msMaxTouchPoints > 0,
  }),
  methods: {
    selectNext() {
      const nItens = this.results.length || this.suggestions.length;
      this.focusIndex = (this.focusIndex + 1) % nItens;
    },
    selectPrevious() {
      if (this.focusIndex == 0) return;
      this.focusIndex--;
    },
    async init() {
      this.loading = true;
      const items = await itemsF();

      // filter items
      const isOwner = this.hasPermission();
      const itemsFiltered = items.filter((item) => {
        if (item.flags) {
          const hasFlag = Object.entries(item.flags).every(
            ([key, value]) =>
              this.hasFlag(key, value) || this.orgHasFlag(key, value)
          );
          if (!hasFlag) return false;
        }
        if (isOwner) return true;

        if (!item.permission) return true;
        return this.hasPermission(item.permission);
      });

      // initialize fuse
      this.fuse = new Fuse(itemsFiltered, {
        keys: [
          // "id", "title", "description", "keywords"
          { name: "title", weight: 0.7 },
          { name: "description", weight: 0.3 },
          { name: "keywords", weight: 0.5 },
        ],
        threshold: 0.6,
      });

      if (this.search) this.performSearch();
      this.loading = false;
      this.initialized = true;

      if (this.isOpen) {
        this.updateHint();
        this.updateSuggestions();
      }
    },
    runFirst() {
      if (this.isMath) return this.mathResultToSearch();
      const first = this.results[this.focusIndex];
      if (first) return this.run(first.item);
      const firstSuggestion = this.suggestions[this.focusIndex];
      if (firstSuggestion) return this.run(firstSuggestion);
    },
    async run(item) {
      if (!item) return;
      if (!this.initialized) await this.init();
      if (this.isMath) return;
      const { type } = item;
      const f = this[`run_${type}`];
      if (f) f(item);
    },
    run_guide(item) {
      this.close();
      this.$root.$emit("guide:run", item.data);
    },
    run_paper(item) {
      this.close();
      this.$root.$emit("paper:run", item.data);
    },
    run_external(item) {
      window.open(item.data.url, "_blank");
      this.close();
    },
    run_internal(item) {
      this.close();
      this.$router.push({
        name: item.data.to,
        params: item.data.params,
        query: item.data.query,
        hash: item.data.hash,
      });
    },
    run_calculator(item) {
      this.search = "=";
      this.$refs.input.focus();
    },

    open({ search } = {}) {
      if (!this.initialized) this.init();
      this.updateHint();
      this.updateSuggestions();
      this.isOpen = true;

      this.$nextTick(() => {
        this.$refs.input?.focus();
        if (search) this.search = search;
        else this.$refs.input?.$refs.input?.select();
      });
    },
    close() {
      this.isOpen = false;
      this.search = "";
    },
    performSearch() {
      if (this.search.startsWith("=")) return this.runMath();
      else this.isMath = false;

      if (!this.fuse) return;
      this.results = this.fuse.search(this.search).slice(0, this.resultLimit);
    },
    runMath() {
      this.isMath = true;
      const expression = this.search.replace("=", "").replace(",", ".");
      if (!expression) return (this.mathResult = null);

      try {
        nerdamer.flush();
        const result = nerdamer(expression).evaluate().text();
        const value = result;
        this.mathResult = { value, isApproximation: result != value };
      } catch (error) {
        this.mathResult = null;
      }
    },
    mathResultToSearch() {
      if (!this.mathResult) return;
      this.search = `=${this.mathResult.value}`;
      this.$refs.input.focus();
      // this.performSearch();
    },
    updateHint() {
      this.hint =
        this.hintPhases[Math.floor(Math.random() * this.hintPhases.length)];
    },
    updateSuggestions() {
      const path = this.$route.path;
      if (!this.fuse) return;
      this.suggestions = this.fuse._docs
        .filter(({ relevantIn }) => {
          if (!relevantIn) return false;
          if (relevantIn.includes("all")) return true;
          return relevantIn.some((url) => {
            if (url.includes(":")) {
              let regExpPath = url.replace(/:\w+/g, ".*");
              let regExp = new RegExp(`^${regExpPath}$`);
              return regExp.test(path);
            } else {
              return url === path;
            }
          });
        })
        .slice(0, this.resultLimit);
    },
  },
  watch: {
    search() {
      this.focusIndex = 0;
      this.performSearch();
    },
    "selectedOrganization.id"() {
      this.initialized = false;
      this.fuse = null;
      this.search = "";
      this.suggestions = [];
      this.results = [];
    },
  },
  computed: {
    ...mapGetters("auth", ["hasPermission", "hasFlag"]),
    ...mapGetters("organization", ["selectedOrganization"]),
    ...mapGetters("organization", { orgHasFlag: "hasFlag" }),
  },

  mounted() {
    this.$root.$on("spotlight:open", this.open);
    this.$root.$on("spotlight:run", this.run);

    document.addEventListener("keydown", (event) => {
      if (event.key === ";" && event.ctrlKey) {
        event.preventDefault();
        this.openUsingShortcut = true;
        this.open();
      }
    });
  },
  beforeDestroy() {
    this.$root.$off("spotlight:open", this.open);
    this.$root.$off("spotlight:run", this.run);
    document.removeEventListener("keydown", function (event) {
      if (event.key === ";" && event.ctrlKey) {
        event.preventDefault();
        this.openUsingShortcut = true;
        this.open();
      }
    });
  },
};
</script>

<style lang="scss" scoped>
.start {
  align-items: start;
}
</style>
