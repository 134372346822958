export default async () => {
  const context = require.context(
    "../../components/global/paper/papers",
    true,
    /\.vue$/
  );

  // Crie um objeto para armazenar os dados de cada arquivo
  const files = [];

  // Itere sobre os módulos encontrados no contexto
  context.keys().forEach((modulePath) => {
    const fileName = modulePath.replace("./", ""); // Remove o "./" do início do caminho
    if (fileName.startsWith("_")) return; // Ignore arquivos que começam com "_"
    const module = context(modulePath);

    // Se o arquivo tiver o parâmetro "manifest", adicione-o ao objeto
    if (module.hasOwnProperty("manifest")) {
      files.push({
        ...module.manifest,
        type: "paper",
        data: {
          paper: fileName.replace(".vue", ""),
        },
      });
    }
  });

  return files;
};
