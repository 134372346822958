<template>
  <div>
    <div style="position: fixed; bottom: 15px; right: 15px; z-index: 10000">
      <v-fade-transition>
        <v-btn
          v-if="tour"
          color="primary"
          rounded
          large
          :class="{ pulse: !resolveFn && !loading }"
          @click="tour.cancel()"
          :loading="loading"
        >
          <v-icon>mdi-shimmer</v-icon>
          <span class="ml-2" v-if="!loading"> Guia interativo </span>
        </v-btn>
      </v-fade-transition>

      <v-fade-transition>
        <v-alert type="error" v-if="error" border="bottom">
          {{ error }}
        </v-alert>
      </v-fade-transition>
    </div>
    <v-dialog
      :value="!!resolveFn"
      style="z-index: 10010"
      @click:outside="closeCancelModal(false)"
      max-width="300px"
    >
      <v-card>
        <v-card-title class="headline justify-center flex-column">
          <v-icon class="mb-2" large>mdi-shimmer</v-icon>
          Sair do guia interativo?
        </v-card-title>
        <!-- <v-card-text class="text-center">
          <span>Tem certeza que deseja cancelar o guia interativo?</span>
        </v-card-text> -->
        <v-card-actions>
          <v-btn color="primary" @click="closeCancelModal(false)"> Não </v-btn>
          <v-spacer />
          <v-btn color="primary" text @click="closeCancelModal(true)">
            Sair do guia
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Shepherd from "shepherd.js";
import "shepherd.js/dist/css/shepherd.css";

export default {
  data: () => ({
    tour: undefined,
    cancelModal: false,
    loading: false,
    resolveFn: undefined,
    error: undefined,
  }),
  methods: {
    openCancelModal() {
      return new Promise((resolve) => {
        this.cancelModal = true;
        this.resolveFn = resolve;
      });
    },
    closeCancelModal(confirm = false) {
      this.resolveFn(confirm);
      this.resolveFn = undefined;
    },
    showError(error, ms = 5000) {
      this.error = error;
      setTimeout(() => {
        this.error = undefined;
      }, ms);
    },
    async run(data) {
      try {
        if (this.loading) return;
        if (this.tour) {
          await this.tour.cancel();
          if (this.tour) return;
        }
        if (Shepherd.activeTour) {
          await Shepherd.activeTour.cancel();
          if (this.tour) return;
        }
        this.loading = true;
        const { guide } = data;
        const { steps, startUrl, ...opts } = await import(
          `../../data/guides/${guide}.js`
        ).then((m) => m.default);
        const tour = new Shepherd.Tour(
          Object.assign(
            {
              confirmCancel: this.openCancelModal,
              useModalOverlay: true,
              defaultStepOptions: {
                classes: "rounded",
                scrollTo: { behavior: "smooth", block: "center" },
              },
            },
            opts
          )
        );

        tour.addSteps(steps({ tour, $vue: this }));
        this.tour = tour;

        if (
          startUrl &&
          startUrl.length &&
          !startUrl.includes(this.$route.path)
        ) {
          this.$router.push(startUrl[0]);
          await new Promise((resolve) => setTimeout(resolve, 1000));
        }

        tour.start();

        tour.on("complete", () => {
          this.tour = undefined;
        });
        tour.on("cancel", () => {
          this.tour = undefined;
        });
      } catch (e) {
        console.log(e);
        this.showError("Erro ao carregar o guia interativo");
      } finally {
        this.loading = false;
      }
    },
  },
  watch: {
    "$route.path": {
      handler() {
        if (!this.tour) return;
        const currentStep = this.tour.getCurrentStep();
        if (!currentStep) return;
        const scopeUris = currentStep.options?.scopeUris;
        if (!scopeUris) return;
        if (scopeUris.includes(this.$route.path)) return;
        this.tour.cancel();
        this.closeCancelModal(true);
      },
    },
  },
  computed: {
    ...mapGetters("auth", ["hasPermission"]),
  },

  mounted() {
    this.$root.$on("guide:run", this.run);
  },
};
</script>

<style lang="scss" scoped>
@keyframes pulse-animation {
  0% {
    box-shadow: 0 0 0 0 rgb(0, 0, 255);
  }
  80% {
    box-shadow: 0 0 0 5px rgba(128, 0, 128, 0.5);
  }
  100% {
    box-shadow: 0 0 0 15px rgba(194, 24, 91, 0);
  }
}

.pulse {
  animation: pulse-animation 2s infinite linear;
}
</style>
