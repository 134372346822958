<template>
  <v-card
    class="pa-2 d-flex gap-4 align-center rounded-lg"
    outlined
    :class="{ 'elevation-5': focus }"
    @click="run(item)"
    :style="computedStyle"
    @mousemove="$emit('focus')"
  >
    <v-icon :large="!focus" :x-large="focus">
      {{ item.icon || types[item.type].icon }}
    </v-icon>
    <div
      class="justify-items-start align-center align-content-center"
      :style="{
        'max-width': item.background ? '65%' : '100%',
        minHeight: '60px',
        display: 'grid',
      }"
    >
      <p
        class="text-overline mb-0"
        style="line-height: normal; font-size: 10px !important"
      >
        {{ types[item.type].title }}
      </p>
      <p class="text-body-1 font-weight-bold text-truncate mb-0">
        {{ item.title }}
      </p>
      <p v-if="item.description" class="text-body-2 text-truncate mb-0">
        {{ item.description }}
      </p>
    </div>
  </v-card>
</template>

<script>
export default {
  data: () => ({
    types: {
      guide: {
        title: "Guia interativo",
        icon: "mdi-shimmer",
      },
      external: {
        title: "Link Externo",
        icon: "mdi-open-in-new",
      },
      internal: {
        title: "Link Interno",
        icon: "mdi-link-variant",
      },
      paper: {
        title: "Documento",
        icon: "mdi-text-box",
      },
      calculator: {
        title: "Calculadora",
        icon: "mdi-calculator-variant",
      },
    },
  }),
  methods: {
    run(item) {
      this.$root.$emit("spotlight:run", item);
    },
  },
  computed: {
    computedStyle() {
      const color = this.$vuetify.theme.isDark ? "#1e1e1e" : "#ffffff";
      return {
        cursor: "pointer",
        "background-image": `linear-gradient(to left, transparent 0%, ${color} 20%), url(${this.item.background})`,
        "background-repeat": "no-repeat",
        "background-size": "auto 110%",
        "background-position": "right center",
      };
    },
  },
  props: {
    item: {
      type: Object,
      required: true,
    },
    focus: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style></style>
