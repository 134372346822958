export default [
  {
    id: "guide:create-event",
    title: "Como criar um evento",
    description: "Aprenda a criar um evento",
    keywords: [
      "criar evento",
      "criar festa",
      "criar uma festa",
      "criar um evento",
      "adicionar evento",
      "adicionar festa",
      "adicionar uma festa",
      "adicionar um evento",
    ],
    permission: [2],
    relevantIn: [
      "/admin/organization/parties",
      "/admin/organization/parties/create",
    ],

    type: "guide",
    data: {
      guide: "createEvent",
    },
  },
  // {
  //   id: "guide:sell-ticket",
  //   title: "Como vender ingressos",
  //   description: "Passo a passo para realizar uma venda de ingresso",
  //   keywords: [
  //     "vender ingresso",
  //     "vender ingressos",
  //     "venda de ingresso",
  //     "venda de ingressos",
  //     "vender convite",
  //     "vender convites",
  //     "venda de convite",
  //     "venda de convites",
  //   ],
  //   type: "guide",
  //   permission: [32, 1024],
  //   relevantIn: ["/admin/organization/sell"],
  //   data: {
  //     guide: "sellTicket",
  //   },
  // },
];
