<template>
  <div class="py-16">
    <h2 class="text-center">404</h2>
    <h6 class="text-center">Documento não encontrada</h6>
  </div>
</template>

<script>
export default {};
</script>

<style></style>
